<template>
  <!-- eslint-disable -->
  <div>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="onSubmit">
        <b-card>
          <b-row>
            <!-- Field: Nombre -->
            <b-col cols="12" md="3">
              <b-form-group label="Nombre" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="contractData.name"
                >
                  <b-form-input
                    id="name"
                    placeholder="Nombre"
                    v-model="contractData.name"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo nombre es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: business_name -->
            <b-col cols="12" md="3">
              <b-form-group label="Empresa" label-for="business_name">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="contractData.business_name"
                >
                  <v-select
                    v-model="contractData.company_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="companyOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    @input="changeBusiness"
                    input-id="business_name"
                    placeholder="Ningun empresa seleccionada"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: responsible_sst -->
            <b-col cols="12" md="3">
              <b-form-group label="Responsable" label-for="responsible">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="contractData.responsible_sst"
                >
                  <v-select
                    v-model="contractData.responsible_sst"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="usersFilterOptions"
                    :reduce="(val) => val.label"
                    :clearable="true"
                    input-id="label"
                    placeholder="Ningun usuario seleccionado"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: development_by -->
            <b-col cols="12" md="3">
              <b-form-group label="Desarrollado por" label-for="development">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="contractData.development_by"
                >
                  <b-form-input
                    id="development"
                    v-model="contractData.development_by"
                    type="text"
                    placeholder="Desarrollado por"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field: expiration_date -->
            <b-col cols="12" md="3">
              <b-form-group
                label="Fecha de expiración"
                label-for="expiration_date"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="contractData.expiration_date"
                >
                  <flat-pickr
                    id="expiration_date"
                    placeholder="Fecha de expiración"
                    v-model="contractData.expiration_date"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                    class="form-control"
                    :config="config"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: evaluation_date -->
            <b-col cols="12" md="3">
              <b-form-group
                label="Fecha de evaluación"
                label-for="evaluation_date"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="contractData.evaluation_date"
                >
                  <flat-pickr
                    id="evaluation_date"
                    placeholder="Fecha de evaluación"
                    v-model="contractData.evaluation_date"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                    class="form-control"
                    :config="config"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: scope -->
            <b-col cols="12" md="3">
              <b-form-group label="Alcance" label-for="scope">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="contractData.scope"
                >
                  <b-form-input
                    id="scope"
                    v-model="contractData.scope"
                    type="text"
                    placeholder="Alcance"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: qty_workers -->
            <b-col cols="12" md="3">
              <b-form-group label="Nro Trabajadores" label-for="qty_workers">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="contractData.qty_workers"
                >
                  <b-form-input
                    id="qty_workers"
                    v-model.number="contractData.qty_workers"
                    type="number"
                    placeholder="Nro Trabajadores"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field: USERS -->
            <b-col cols="12" md="4">
              <b-form-group label="Usuarios" label-for="contract_user">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="contractData.contract_user"
                >
                  <v-select
                    v-model="contractData.contract_user"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="usersOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    multiple
                    input-id="contract_user"
                    placeholder="Ningun usuario seleccionado"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo nombre es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="8">
              <b-form-group label="Editar Detalle" label-for="check_edit">
                <b-form-checkbox
                  class="mt-1"
                  v-model="selected"
                  id="check_edit"
                  @click="selected = !selected"
                >
                  Editar Detalle
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <!-- Field: TEMPLATES -->
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
                v-if="!selected"
                :disabled="isDisabled"
              >
                <b-spinner v-if="isDisabled" small />
                <span v-if="isDisabled" class="px-1">guardando...</span>
                <span v-else>Guardar cambios</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-card v-if="selected" class="paddingMinus">
          <b-row class="py-2 px-3">
            <b-col cols="12">
              <b-row>
                <b-col cols="12" class="d-flex justify-content-end">
                  <b-button
                    variant="warning"
                    class=""
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="repeateAgain"
                  >
                    Añadir
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row
            v-for="(item, index) in contractData.contract_detail"
            :id="index"
            :key="index"
            ref="row"
            class="repeater-form-row mb-2 pb-2 pt-1 mx-1 mx-xl-3"
          >
            <!-- Field: Question -->
            <b-col cols="12" md="10">
              <b-form-group
                :label="item.number + '. Titulo'"
                label-size="sm"
                label-for="Titulo"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="{ required: selected }"
                  name="item.description"
                >
                  <b-form-textarea
                    :value="item.description"
                    v-model="item.description"
                    id="Titulo"
                    placeholder="Titulo"
                    rows="3"
                    no-resize
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo nombre es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="2" class="pt-md-1 pb-1">
              <b-row>
                <b-col cols="12" class="d-flex justify-content-center">
                  <b-button
                    variant="danger"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    class=""
                    @click="removeItem(index)"
                  >
                    - Eliminar
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="d-flex justify-content-center">
                  <b-button
                    variant="success"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    class="mt-1"
                    @click="repeateAgain2(index)"
                  >
                    + Agregar
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <!-- <b-col cols="12" md="2" class="my-auto text-right">
              
            </b-col> -->
            <b-col
              cols="12"
              md="12"
              class="content_detail"
              :class="{ content_detail_add: item.children.length > 1 }"
            >
              <b-form ref="form2" @submit.prevent="repeateAgain2">
                <b-row>
                  <b-col cols="12" md="12" class="mb-1">
                    <b-card no-body class="mb-0">
                      <b-table
                        class="position-relative"
                        :hover="true"
                        :items="item.children"
                        responsive
                        :fields="fields"
                        show-empty
                        stacked="md"
                        empty-text="No existen"
                        :sticky-header="false"
                      >
                        <!-- Column: USER -->
                        <template #cell(index)="data">
                          <b-media vertical-align="center">
                            {{ data.item.number }}
                          </b-media>
                        </template>
                        <!-- Column: USER -->
                        <template #cell(description)="data">
                          <b-media vertical-align="center">
                            <validation-provider
                              #default="{ errors }"
                              :rules="{ required: selected }"
                              name="data.item.description"
                            >
                              <b-form-textarea
                                v-model="data.item.description"
                                id="Descripcion"
                                placeholder="Descripcion"
                                rows="2"
                                no-resize
                              />
                              <small
                                v-if="errors.length > 0"
                                class="text-danger"
                                >El campo nombre es requerido</small
                              >
                            </validation-provider>
                          </b-media>
                        </template>
                        <!-- Column: USER -->
                        <template #cell(ref_legal)="data">
                          <b-media vertical-align="center">
                            <b-form-textarea
                              v-model="data.item.ref_legal"
                              placeholder="Ref. legal"
                              id="ref_legal"
                              rows="2"
                              no-resize
                            />
                          </b-media>
                        </template>
                        <!-- Column: USER -->
                        <template #cell(finding)="data">
                          <b-media vertical-align="center">
                            <b-form-textarea
                              placeholder="Hallazgo o evidencia"
                              v-model="data.item.finding"
                              id="finding"
                              rows="2"
                              no-resize
                            />
                          </b-media>
                        </template>
                        <!-- Column: Actions -->
                        <template #cell(action)="data">
                          <b-button
                            @click="removeItemSub(data.index, index)"
                            size="md"
                            class=""
                            variant="flat-danger"
                          >
                            <feather-icon
                              variant=""
                              size="20"
                              icon="Trash2Icon"
                            />
                          </b-button>
                        </template>
                      </b-table>
                    </b-card>
                  </b-col>
                </b-row>
              </b-form>
            </b-col>
          </b-row>
          <b-row class="px-1 px-xl-0 mx-xl-2">
            <b-col cols="12">
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
                :disabled="isDisabled"
              >
                <b-spinner v-if="isDisabled" small />
                <span v-if="isDisabled" class="px-1">guardando...</span>
                <span v-else>Guardar cambios</span>
              </b-button>
            </b-col>
          </b-row>
          <!-- <div>
            <b-form
              ref="form"
              class="repeater-form px-2 pt-2 mb-2"
              @submit.prevent="repeateAgain"
              style="position: relative; max-height: 700px; overflow-y: scroll"
            >
            </b-form>            
          </div> -->
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BTable,
  BMedia,
  BFormGroup,
  BFormInput,
  BForm,
  BFormSelect,
  BModal,
  BLink,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BFormTextarea,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import CompanyService from "@/services/CompanyService";
import UserService from "@/services/UserService";
import TemplateService from "@/services/TemplateService";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ContractService from "@/services/ContractService";
import flatPickr from "vue-flatpickr-component";
import { heightTransition } from "@core/mixins/ui/transition";
export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BTable,
    BLink,
    BMedia,
    flatPickr,
    BCardCode,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BFormSelect,
    BFormTextarea,
  },
  data() {
    return {
      required,
      contractData: {},
      currentPage: 1,
      dateDefault: null,
      showEntrie: 50,
      sort: "id",
      order: "asc",
      companyOptions: [],
      usersOptions: [],
      usersFilterOptions: [],
      isDisabled: false,
      templateOptions: [],
      templateFormat: [],
      config: {
        dateFormat: "Y-m-d",
        // minDate: "today"
      },
      selected: true,
      fields: [
        {
          key: "index",
          label: "Nro",
          thStyle: { width: "2%" },
        },
        {
          key: "description",
          label: "Descripcion",
          thStyle: { width: "53%" },
        },
        {
          key: "ref_legal",
          label: "Ref. Legal",
          thStyle: { width: "20%" },
        },
        {
          key: "finding",
          label: "Hallazgo o Ev.",
          thStyle: { width: "20%" },
        },
        {
          key: "action",
          label: "Acciones",
          thStyle: { width: "5%" },
        },
      ],
    };
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  mounted() {
    this.getData();
    // this.initTrHeight();
  },
  // created() {
  //   window.addEventListener("resize", this.initTrHeight);
  // },
  // destroyed() {
  //   window.removeEventListener("resize", this.initTrHeight);
  // },
  methods: {
    async changeBusiness() {
      //USUARIOS
      this.contractData.contract_user = [];
      this.usersOptions = [];
      const url1 = `${this.contractData.company_id}/CONTRATISTA`;
      const resp1 = await UserService.getUsersByCompany(url1, this.$store);
      console.log("resp1", resp1);
      if (resp1) {
        resp1.data.forEach((element) => {
          this.usersOptions.push({
            label: element.name + " " + element.last_name,
            value: {
              user_id: element.id,
            },
          });
        });
      }
    },
    async getData() {
      //USERS GENERAL
      let arrayFilters = [
        {
          key: "equals",
          keyContains: "role",
          value: "jefe_area",
        },
      ];

      const urlUser =
        `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}&filter=` +
        JSON.stringify(arrayFilters);
      const respUser = await UserService.getUsers(urlUser, this.$store);

      if (respUser) {
        respUser.rows.forEach((element) => {
          this.usersFilterOptions.push({
            label: element.name + " " + element.last_name,
            value: {
              user_id: element.id,
            },
          });
        });
      }
      //DATA
      const resp1 = await ContractService.getContract(
        this.$route.params.id,
        this.$store
      );
      if (resp1.success) {
        this.contractData = resp1.data;
        console.log("RESP DATA", this.contractData);
        //USUARIOS
        const url1 = `${this.contractData.company_id}/CONTRATISTA`;
        const resp2 = await UserService.getUsersByCompany(url1, this.$store);
        console.log("resp1", resp1);
        if (resp1) {
          resp2.data.forEach((element) => {
            this.usersOptions.push({
              label: element.name + " " + element.last_name,
              value: {
                user_id: element.id,
              },
            });
          });
        }
      }
      //EMPRESAS
      const url = `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}`;
      const resp = await CompanyService.getCompanys(url, this.$store);
      if (resp) {
        console.log(633, resp);
        resp.rows.forEach((element) => {
          this.companyOptions.push({
            label: element.business_name,
            value: element.id,
          });
        });
      }
      //TEMPLATES
      const url2 = `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}`;
      const resp2 = await TemplateService.getTemplates(url2, this.$store);
      if (resp2) {
        resp2.rows.forEach((element) => {
          this.templateOptions.push({
            label: element.name,
            value: element.id,
          });
        });
      }
    },
    async onSubmit(data) {
      const isValid = await this.$refs.simpleRules.validate();
      if (!isValid) {
        console.log("NO VALIDADO");
      } else {
        this.isDisabled = true;
        var contractData = {
          name: this.contractData.name,
          development_by: this.contractData.development_by,
          responsible_sst: this.contractData.responsible_sst,
          template_id: this.contractData.template_id,
          company_id: this.contractData.company_id,
          scope: this.contractData.scope,
          evaluation_date: new Date(this.contractData.evaluation_date),
          expiration_date: new Date(this.contractData.expiration_date),
          qty_workers: this.contractData.qty_workers,
          contract_detail: [],
          contract_user: [],
        };
        if (this.selected === false) {
          contractData.allow_edit = false;
        }
        for (
          let index = 0;
          index < this.contractData.contract_user.length;
          index++
        ) {
          const element = {
            user_id: this.contractData.contract_user[index].user_id,
            contract_id: parseInt(this.$route.params.id),
          };
          contractData.contract_user.push(element);
        }
        if (this.selected === true) {
          for (let i = 0; i < this.contractData.contract_detail.length; i++) {
            var children = [];
            for (
              let j = 0;
              j < this.contractData.contract_detail[i].children.length;
              j++
            ) {
              const elementChild = {
                number: this.contractData.contract_detail[i].children[j].number,
                description:
                  this.contractData.contract_detail[i].children[j].description,
                ref_legal:
                  this.contractData.contract_detail[i].children[j].ref_legal,
                finding:
                  this.contractData.contract_detail[i].children[j].finding,
                status: "pending",
                compliance: "NA",
                compliance_percentage: 0,
                contract_id: parseInt(this.$route.params.id),
              };
              children.push(elementChild);
            }
            const element = {
              number: this.contractData.contract_detail[i].number,
              description: this.contractData.contract_detail[i].description,
              ref_legal: this.contractData.contract_detail[i].ref_legal,
              finding: this.contractData.contract_detail[i].finding,
              status: "pending",
              compliance: "NA",
              compliance_percentage: 0,
              contract_id: parseInt(this.$route.params.id),
              children: {
                create: children,
              },
            };
            contractData.contract_detail.push(element);
          }
        }
        if (this.selected === false) {
          const resp = await ContractService.updateContract(
            this.$route.params.id,
            contractData,
            this.$store
          );
          if (resp.success) {
            this.$swal({
              title: "Actualizado",
              text: "El contrato ha sido actualizado",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.$router.push("/contract/list");
          } else {
            this.$swal({
              title: "Error!",
              text: " Hubo un error al editar el contrato",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        } else {
          this.$swal({
            title: "¿Desea editar este contrato?",
            text: "La edición eliminará su progreso hasta ahora.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, editalo",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then(async (result) => {
            if (result.value) {
              const resp = await await ContractService.updateContract(
                this.$route.params.id,
                contractData,
                this.$store
              );
              if (resp) {
                this.$router.push("/contract/list");
                this.$swal({
                  icon: "success",
                  title: "Actualizado!",
                  text: "El contrato ha sido actualizado",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              } else {
                this.isDisabled = false;
                this.$swal({
                  title: "Error!",
                  text: " Hubo un error al editar el contrato",
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
            } else {
              this.isDisabled = false;
            }
          });
        }
      }
    },
    repeateAgain() {
      this.contractData.contract_detail.push({
        parent_id: null,
        description: "",
        number: "",
        ref_legal: "",
        finding: "",
        children: [],
      });
      console.log(
        "this.contractData.contract_detail",
        this.contractData.contract_detail
      );
      // this.$nextTick(() => {
      //   this.trAddHeight(this.$refs.row[0].offsetHeight);
      // });
      this.recalcularPadre();
    },
    repeateAgain2(id) {
      this.contractData.contract_detail[id].children.push({
        description: "",
        number: "",
        ref_legal: "",
        finding: "",
        finding: "",
      });
      this.recalcularHijos(id);
      console.log(
        "this.contractData.contract_detail[id].children",
        this.contractData.contract_detail[id].children
      );
    },
    removeItem(index) {
      this.contractData.contract_detail.splice(index, 1);
      // this.trTrimHeight(this.$refs.row[0].offsetHeight);
      this.recalcularPadre();
    },
    removeItemSub(indexChild, indexParent) {
      console.log(
        "indexParent",
        indexParent,
        this.contractData.contract_detail[indexParent].children
      );
      console.log("indexChild", indexChild);
      this.contractData.contract_detail[indexParent].children.splice(
        indexChild,
        1
      );
      this.recalcularHijos(indexParent);
    },
    recalcularHijos(indexParent) {
      //recalcula los numeros de un padre
      for (
        let i = 0;
        i < this.contractData.contract_detail[indexParent].children.length;
        i++
      ) {
        this.contractData.contract_detail[indexParent].children[i].number =
          this.contractData.contract_detail[indexParent].number +
          "." +
          (i + 1).toString();
      }
    },
    recalcularPadre() {
      for (let i = 0; i < this.contractData.contract_detail.length; i++) {
        this.contractData.contract_detail[i].number = (i + 1).toString();
        this.recalcularHijos(i);
      }
    },
    // initTrHeight() {
    //   console.log("!this.selected", !this.selected);
    //   this.trSetHeight(null);
    //   this.$nextTick(() => {
    //     this.trSetHeight(this.$refs.form.scrollHeight);
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.modal_button {
  display: flex;
  justify-content: end;
}
.modal_title {
  display: flex;
  align-items: center;
}
.b-form-btn-label-control.form-control > label {
  height: auto !important;
}
.card-body {
  padding: 1rem;
}
.paddingMinus {
  margin-bottom: -2rem;
}
.add-question {
  padding-top: 0.5em;
}
.repeater-form-row {
  background: rgba(220, 217, 217, 0.615);
  border-radius: 5px;
}
.repeater-form {
  // height: 300px !important;
  max-height: 380px !important;
}
</style>
